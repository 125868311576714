import { Table, TableProps } from "../Table/Table";
import Typography from "@/foundation/Typography/Typography";
import { formatCamelCaseString, formatCurrency } from "@shared/utils";
import {
  Funding,
  Seller,
  Giftors,
  PurchaseIntent,
} from "../../pages/TransactionDetails/types";
import { Card } from "@/components/Card/Card";
import { BuyerSellerBlock } from "../BuyerSellerBlock";
import { useEffect, useState } from "react";
import { SignificantDatesCard } from "../SignificantDatesCard/SignificantDatesCard";
import { useTransactionContext } from "../../context/TransactionProvider";

interface PurchaseIntentTabProps {
  purchaseIntent: PurchaseIntent;
  purchaseIntentIndex: number;
  onUpdatedPurchaseIntent: (newPurchaseIntent: PurchaseIntent) => void;
}

const PurchaseIntentCard = ({
  purchaseIntent,
  purchaseIntentIndex,
  onUpdatedPurchaseIntent,
}: PurchaseIntentTabProps) => {
  const [buyers, setBuyers] = useState<Array<Seller & { giftors?: Giftors[] }>>(
    [],
  );
  const [purchaseIntentInfo, setPurchaseIntentInfo] = useState<TableProps[]>(
    [],
  );

  useEffect(() => {
    const getFundingValues = (values: Funding) => {
      const formattedValues = Object.entries(values)
        .filter(([key, value]) => key !== "_id" && !!value)
        .map(([key, value]) => {
          if (key === "gifts") {
            if (value === "no") {
              return null; // Exclude "gifts" if its value is "no"
            }
            if (value === "not_sure") {
              return "Gifts (Maybe)";
            }
          }
          return formatCamelCaseString(key);
        })
        .filter(Boolean);

      return formattedValues.join(", ");
    };

    setBuyers(purchaseIntent.buyers);
    setPurchaseIntentInfo([
      {
        label: "Agreed Price",
        value: formatCurrency(purchaseIntent.agreed_price, true, true),
      },
      {
        label: "Mortgage Required",
        value: purchaseIntent.mortgage_required_for_purchase,
      },
      {
        label: "Mortgage Provider",
        value: purchaseIntent.mortgage_provider,
      },
      { label: "Funding", value: getFundingValues(purchaseIntent.funding) },
      {
        label: "Funding Arrangement Comments",
        value: purchaseIntent.other_funding_comments
          ? purchaseIntent.other_funding_comments
          : "None",
      },
      {
        label: "Purchase Dependent on Sale",
        value: purchaseIntent.purchase_dependent_on_sale.toString(),
      },
      {
        label: "Ownership Type",
        value: purchaseIntent.ownership_type,
      },
      {
        label: "First Time Buyer",
        value: purchaseIntent.first_time_buyer.toString(),
      },
    ]);
  }, [purchaseIntent]);

  return (
    <>
      <Card title={`Purchase Intent ${purchaseIntentIndex}`}>
        <>
          <div className="flex flex-wrap w-full gap-[24px]">
            <div className="flex-1">
              <Card
                title="Purchase Intent Info"
                titleBackgroundColor="brand-heavy-warmth-25"
                titleColor="brand-heavy-teal-100"
              >
                <>
                  {purchaseIntentInfo.map(({ value, label }) => (
                    <Table
                      key={`purchase-intent-${purchaseIntentIndex}-${label}`}
                      value={value}
                      label={label}
                    />
                  ))}
                </>
              </Card>
            </div>

            <div className="w-[350px]">
              <SignificantDatesCard
                id={purchaseIntent._id}
                significantDates={purchaseIntent.significant_dates || {}}
                onSiginificantDatesUpdated={onUpdatedPurchaseIntent}
              />
            </div>
          </div>

          <Typography
            type="h3"
            variant="h3"
            weight="bold"
            className="text-brand-heavy-teal-100 font-display w-[505px] my-[32px]"
          >
            Buyers
          </Typography>

          <div className="flex flex-col space-y-5">
            {buyers.map((buyer, index) => (
              <BuyerSellerBlock key={`seller-${index}`} buyerSeller={buyer} />
            ))}
          </div>
        </>
      </Card>
    </>
  );
};

export const PurchaseIntentTab = () => {
  const { transaction, setTransaction } = useTransactionContext();

  return transaction?.purchase_intent.map((purchaseIntent, index) => (
    <PurchaseIntentCard
      key={`purchase-intent-${index}`}
      purchaseIntentIndex={index + 1}
      purchaseIntent={purchaseIntent}
      onUpdatedPurchaseIntent={(newPurchaseIntent) => {
        const updatedPurchaseIntents = transaction.purchase_intent.map(
          (pi, i) => (i === index ? newPurchaseIntent : pi),
        );

        setTransaction &&
          setTransaction({
            ...transaction,
            purchase_intent: updatedPurchaseIntents,
          });
      }}
    />
  ));
};
