import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import { Button } from "@/components/Button/Button";
import { Card } from "@/components/Card/Card";
import Typography from "@/foundation/Typography/Typography";
import Icons from "@/foundation/Icons/Icons";
import { capitalizeFirstLetter } from "@shared/utils";

import { OrderSearchModal } from "../Modals/OrderSearchModal";
import { Address } from "../../pages/UserDetails/types";
import { useTransactionContext } from "../../context/TransactionProvider";

const searchProductMapping: { [key: string]: string } = {
  LLC1CON29: "Local",
  ONESEARCHDW: "Drainage & Water",
  ENVRES: "Environmental",
  LANDREGPLAN: "Land Registry Plan",
};

export const SearchesTab = () => {
  const { transactionEvents, transaction, setTransaction } =
    useTransactionContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchesOrderConditionsMet, setSearchesOrderConditionsMet] =
    useState(false);

  const [propertyAddress, setPropertyAddress] = useState<Address | undefined>();
  const [transactionId, setTransactionId] = useState<string | undefined>();

  const [legacySearchesOrdered, setLegacySearchesOrdered] = useState(false); // legacy transactions have searches ordered before 24/09/2024

  useEffect(() => {
    const laywersAssigned = !!transaction?.lawyer_group;
    const transactionPaid = transactionEvents.some(
      (event) => event.event_name === "payment_successful",
    );

    setSearchesOrderConditionsMet(transactionPaid && laywersAssigned);

    const searchOrderedEventPresent = transactionEvents.some(
      (event) => event.event_name === "search_ordered",
    );

    setLegacySearchesOrdered(searchOrderedEventPresent);
    setPropertyAddress(transaction?.property_id?.address);
    setTransactionId(transaction?._id);
  }, [transaction, transactionEvents]);

  return (
    <>
      <Card
        title="Searches"
        titleBackgroundColor="brand-heavy-warmth-25"
        titleColor="brand-heavy-teal-100"
        titleRightContent={
          transaction?.searches && (
            <Link
              to={`https://onesearch.direct/previous_orders/order/${transaction.searches?.reference}/`}
              className="flex"
              target="_blank"
            >
              <Typography className="mr-[8px]">View</Typography>
              <div className="mt-[1.5px]">
                <Icons iconName="NewWindow" />
              </div>
            </Link>
          )
        }
      >
        {transaction?.searches ? (
          <div>
            {transaction.searches.ordered.map((search) => (
              <div
                key={search.productId}
                className="mb-[28px] flex items-start"
              >
                <div>
                  <Typography
                    variant="h4"
                    weight="bold"
                    className="text-brand-heavy-teal-100"
                  >
                    {`${searchProductMapping[search.productId]} (${search.productId})`}
                  </Typography>

                  <div className="flex mt-[8px] items-center">
                    <Typography
                      weight="bold"
                      variant="lg"
                      className={
                        search.status === "in progress"
                          ? "text-ui-warning-100"
                          : "text-ui-success-100"
                      }
                    >
                      {capitalizeFirstLetter(search.status)}
                    </Typography>
                    <Typography
                      weight="semi-bold"
                      variant="lg"
                      className="mx-[8px] text-brand-heavy-teal-100"
                    >
                      |
                    </Typography>
                    <Typography
                      weight="semi-bold"
                      variant="lg"
                      className="text-brand-heavy-teal-100"
                    >
                      {search.status === "in progress"
                        ? `${search.estimatedCompletion ? `${format(new Date(search.estimatedCompletion), "dd/MM/yyyy")} (est)` : "estimation date unknown"}`
                        : `${search.completionDate ? format(new Date(search.completionDate), "dd/MM/yyyy") : "completion date unknown"}`}
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <Typography variant="lg" weight="semi-bold" className="mb-[16px]">
              {legacySearchesOrdered
                ? "Searches have been ordered for this transaction"
                : "No searches have been ordered yet."}
            </Typography>
            <Button
              variant="primary"
              onClick={() => setIsModalOpen(true)}
              disabled={!searchesOrderConditionsMet || legacySearchesOrdered}
            >
              Order Searches
            </Button>
          </div>
        )}
      </Card>
      {isModalOpen && propertyAddress && transactionId && setTransaction && (
        <OrderSearchModal
          onClose={() => setIsModalOpen(false)}
          propertyAddress={propertyAddress}
          transactionId={transactionId}
          onSearchesOrdered={setTransaction}
        />
      )}
    </>
  );
};
